import React, { useEffect, useState } from "react";
import { InnovationContainer } from "./Innovation.style";
import { Seo, PageBuilder } from "../../components";
import { sanityFetchAllByType } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Innovation = () => {
  const [innovationData, setInnovationData]: any = useState();
  useEffect(() => {
    let fetchInnovationData = sanityFetchAllByType("innovationPage");

    fetchInnovationData.then((res) => {
      setInnovationData(res[0]);
    });
  }, []);

  if (innovationData === undefined) {
    return <LoaderComponent />;
  }
  return (
    <InnovationContainer>
      <Seo
        title="Abcopro | Power To Clean | Innovation"
        description="Abcopro | Power To Clean | Innovation"
        keyword="Innovation"
      />
      <PageBuilder pageBuilder={innovationData.pageBuilder} />
    </InnovationContainer>
  );
};

export default Innovation;

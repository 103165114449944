import styled from "styled-components";
import { motion } from "framer-motion";
import device from "../../utils/deviceScreenSizes";

export const ContactFormContainer = styled(motion.div)`
  padding: 2rem 2rem;
  overflow: auto;

  @media ${device.mobileL} {
    padding: 2rem 2rem;
  }

  .close-button {
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    span {
      margin-left: auto;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .submitBtn {
    padding: 1rem 2rem;
    border: 1px solid #fff;
    border-radius: 2.5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    -webkit-transition: 0.1s ease-in all;
    transition: 0.1s ease-in all;
    display: block;
    width: 100%;
    &:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  max-width: 450px;
  border-top: 1px solid #bdbdbd;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  border-radius: 2.5px;
  .close {
    position: absolute;
    right: 45px;
    cursor: pointer;
    top: 25px;
    svg {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
  }

  p {
    line-height: 32px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  form {
    text-align: center;
    & > div {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    input,
    textarea {
      background: #f4f6f9;
    }
    button {
      margin-top: 3rem;
      @media ${device.mobileL} {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
`;
export const FormContainer = styled(motion.div)`
  padding: 2rem 2rem;
  overflow: auto;
  position: relative;
  padding-bottom: 7rem;
  min-height: 950px;
  .checkbox {
    display: flex;
    align-items: center;
    margin-block: 1rem;
    & > input {
      max-width: 50px;
      min-height: 19px;
    }
  }
  .form-title {
    padding-bottom: 1rem;
    border-bottom: 1px solid #bdbdbd;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .form-field-container {
    padding-bottom: 1rem;
  }
  .custom-select {
    position: relative;

    select {
      font-size: 1rem;
      padding: 0.8rem 1rem;
      border: 1px solid #bdbdbd;
      border-radius: 2.5px;
      width: 100%;
      background: #f4f6f9;
    }

    .custom-arrow {
      position: absolute;
      width: 40px;
      height: 90%;
      background: #f4f6f9;
      right: 2px;
      top: 2px;
      pointer-events: none;

      &::after {
        position: absolute;
        content: "";
        border-style: solid;
        border-width: 7px;
        border-color: ${(p) => p.theme.colors.primary} transparent transparent
          transparent;
        top: 45%;
        left: 35%;
      }
    }
  }
  .campaign-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 33px;
    bottom: 32px;
    width: 88% !important;
    & > svg {
      font-size: 1.5rem;
    }
  }
  .thank-you-step {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 4rem;
      text-decoration: underline #bdbdbd;
      text-underline-offset: 12px;
      text-decoration-thickness: 2px;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 20rem;
      margin-top: 1rem;
    }
  }
  span {
    text-align: left;
    padding: 0.2rem;
    font-size: 0.8rem;
    color: red;
    min-height: 22px;
  }

  .campaign-submit.custom-btn {
    border: 2px solid #fff;
    background: #669933;
    color: #fff;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border: 2px solid #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.1s ease-in all;
    &:hover {
      background: ${(p) => p.theme.colors.primary};
      /* margin-top: 0; */
    }
  }

  @media ${device.mobileL} {
    padding: 2rem 2rem;
  }

  .campaign-submit {
    width: 100%;
  }

  .close-button {
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    span {
      margin-left: auto;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .submitBtn {
    padding: 1rem 2rem;
    border: 1px solid #fff;
    border-radius: 2.5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    -webkit-transition: 0.1s ease-in all;
    transition: 0.1s ease-in all;
    display: block;
    width: 100%;
    &:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  max-width: 550px;
  border-top: 1px solid #bdbdbd;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  border-radius: 2.5px;
  .close {
    position: absolute;
    right: 45px;
    cursor: pointer;
    top: 25px;
    svg {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
  }

  p {
    line-height: 32px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  form {
    text-align: center;
    & > div {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    input,
    textarea {
      background: #f4f6f9;
    }
    button {
      margin-top: 3rem;
      @media ${device.mobileL} {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
`;
export const MultiStepFormContainer = styled(motion.div)`
  padding: 2rem 2rem;
  overflow: auto;
  position: relative;
  min-height: 950px;

  .form-title {
    padding-bottom: 1rem;
    border-bottom: 1px solid #bdbdbd;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .form-field-container {
    padding-bottom: 1rem;
  }
  .custom-select {
    position: relative;

    select {
      font-size: 1rem;
      padding: 0.8rem 1rem;
      border: 1px solid #bdbdbd;
      border-radius: 2.5px;
      width: 100%;
      background: #f4f6f9;
      /* width: 100%;
    font-size: 1.2rem;
    padding: 10px 30px 10px 10px;
    border: none;
    box-shadow: 0px 0px 10px silver;
    background-color: #454857;
    color: white;
    outline: none; */
    }

    .custom-arrow {
      position: absolute;
      width: 40px;
      height: 90%;
      background: #f4f6f9;
      right: 2px;
      top: 2px;
      pointer-events: none;

      &::after {
        position: absolute;
        content: "";
        border-style: solid;
        border-width: 7px;
        border-color: ${(p) => p.theme.colors.primary} transparent transparent
          transparent;
        top: 45%;
        left: 35%;
      }
    }
  }

  /* span::before {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 7px;
    border-color: transparent transparent #5b6287 transparent;
    top: 14%;
    left: 35%;
  } */

  .thank-you-step {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 4rem;
      text-decoration: underline #bdbdbd;
      text-underline-offset: 12px;
      text-decoration-thickness: 2px;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 20rem;
      margin-top: 1rem;
    }
  }

  @media ${device.tablet} {
    margin: 0 auto;
  }
  .hideForm {
    display: none;
  }

  .showForm {
    display: block;
  }
  .step-wrapper {
    position: relative;
    min-height: 6rem;
    padding-block: 0.5rem;
    .step-list-container {
      width: 100%;
      position: absolute;
      z-index: 1;
      ul {
        list-style: none;
      }
      .progressbar {
        counter-reset: step;
      }
      .progressbar li {
        float: left;
        width: 20%;
        position: relative;
        text-align: center;
      }
      .progressbar li:before {
        content: counter(step);
        counter-increment: step;
        width: 30px;
        height: 30px;
        border: 2px solid #bebebe;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        background: white;
        color: #bebebe;
        text-align: center;
        font-weight: bold;
      }
      .progressbar li.active + li:before {
        content: counter(step);
        counter-increment: step;
        width: 30px;
        height: 30px;
        border: 2px solid #bebebe;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        background: white;
        color: #bebebe;
        border-color: #3aac5d;
        background: #3aac5d;
        color: white;
        text-align: center;
        font-weight: bold;
      }
      .progressbar li:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background: #979797;
        top: 15px;
        left: -50%;
        z-index: -1;
      }
      .progressbar li:first-child:after {
        content: none;
      }

      .progressbar li.active + li:after {
        background: #3aac5d;
      }
      .progressbar li.active + li:before {
        border-color: #3aac5d;
        background: #3aac5d;
        color: white;
      }
    }
  }

  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    transition: all ease 0.3s;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    color: white;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.completed .step-counter {
    background: ${(p) => p.theme.colors.primary};
    color: white;
    font-weight: bold;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  .control-container {
    display: flex;
    & > div {
      flex: 1;
    }
  }

  @media ${device.mobileL} {
    padding: 2rem 2rem;
  }

  .campaign-submit,
  .campaign-next,
  .campaign-prev {
    width: 100%;
    margin-top: 1rem;
  }
  .campaign-prev {
    position: absolute;
    left: 30px;
    bottom: 32px;
    width: 43%;
  }
  .campaign-next {
    position: absolute;
    bottom: 32px;
    right: 30px;
    width: 43%;
  }

  .close-button {
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    span {
      margin-left: auto;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .submitBtn {
    padding: 1rem 2rem;
    border: 1px solid #fff;
    border-radius: 2.5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    -webkit-transition: 0.1s ease-in all;
    transition: 0.1s ease-in all;
    display: block;
    width: 100%;
    &:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  max-width: 550px;
  border-top: 1px solid #bdbdbd;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  border-radius: 2.5px;
  .close {
    position: absolute;
    right: 45px;
    cursor: pointer;
    top: 25px;
    svg {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
  }

  p {
    line-height: 32px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  form {
    text-align: center;
    & > div {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    input,
    textarea {
      background: #f4f6f9;
    }
    button {
      margin-top: 3rem;
      @media ${device.mobileL} {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
`;
export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox {
    display: flex;
    align-items: center;
    margin-block: 1rem;
    & > input {
      max-width: 50px;
      min-height: 19px;
    }
  }
  .form-field-container {
    .checkbox {
      display: flex;
      align-items: center;
      margin-block: 1rem;
      & > input {
        max-width: 50px;
        min-height: 19px;
      }
    }
  }
  .custom-select {
    position: relative;

    select {
      font-size: 1rem;
      padding: 0.8rem 1rem;
      border: 1px solid #bdbdbd;
      border-radius: 2.5px;
      width: 100%;
      background: #f4f6f9;
      /* width: 100%;
    font-size: 1.2rem;
    padding: 10px 30px 10px 10px;
    border: none;
    box-shadow: 0px 0px 10px silver;
    background-color: #454857;
    color: white;
    outline: none; */
    }

    .custom-arrow {
      position: absolute;
      width: 40px;
      height: 90%;
      background: #f4f6f9;
      right: 2px;
      top: 2px;
      pointer-events: none;

      &::after {
        position: absolute;
        content: "";
        border-style: solid;
        border-width: 7px;
        border-color: ${(p) => p.theme.colors.primary} transparent transparent
          transparent;
        top: 45%;
        left: 35%;
      }
    }
  }
  .error {
    border-color: red;
  }

  label {
    font-size: 1rem;
    font-weight: 800;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: left;
  }
  input,
  textarea {
    font-size: 1rem;
    padding: 0.8rem 1rem;
    border: 1px solid ${(p) => p.theme.colors.gray3};
    border-radius: 2.5px;
    width: 100%;
  }

  span {
    text-align: left;
    padding: 0.2rem;
    font-size: 0.8rem;
    color: red;
    min-height: 22px;
  }
`;
export const DownloadCatalogueFormContainer = styled(motion.div)`
  position: absolute;
  border-top: 1px solid #bdbdbd;
  right: -2px;
  height: 100vh;
  /* position: relative; */
  padding: 2rem 2rem;
  max-width: 450px;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  border-left: 1px solid ${(p) => p.theme.colors.gray3};
  border-bottom: 1px solid ${(p) => p.theme.colors.gray3};
  border-radius: 2.5px;
  top: 0;
  z-index: 99;
  .close-button {
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    span {
      margin-left: auto;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .submit-loader {
    padding-top: 0;
    padding-bottom: 0;
  }
  .submitBtn {
    padding: 0.8rem 2rem;
    margin-top: 1rem;
    border: 1px solid #fff;
    border-radius: 2.5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    -webkit-transition: 0.1s ease-in all;
    transition: 0.1s ease-in all;
    display: block;
    width: 100%;
    &:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
  }

  p {
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    padding-right: 2rem;
  }

  form {
    text-align: center;
    & > div {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    input,
    textarea {
      background: #f4f6f9;
    }
    button {
      margin-top: 3rem;
      @media ${device.mobileL} {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
  .skeleton-image-loader {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 10%, 70%);
    }
    100% {
      background-color: hsl(200, 10%, 95%);
    }
  }
  .thumbnail-container {
    /* margin: 0 auto; */
    width: 100%;
    height: 300px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .image-background {
      width: 100%;
      height: 100%;
      background: white;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
      }
    }
  }
  .close {
    position: absolute;
    right: 45px;
    cursor: pointer;
    top: 25px;
    svg {
      font-size: 1.5rem;
    }
  }
  .contact-info {
    display: flex;
    .phone,
    .email {
      padding: 0.8rem;
      border-radius: 3px;
      border: 1px solid ${(p) => p.theme.colors.gray3};
      .info-container {
        display: flex;
        svg {
          font-size: 1.2rem;
        }
      }
    }
    .email {
      margin-left: auto;
    }
    /* .info-container {
      .phone,
      .email {
        padding: 0.5rem;
        border: 1px solid;
        display: flex;
      }
    } */
  }
`;
export const LoadingSpinnerContainer = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    margin: 0 auto;
    width: 25px;
    height: 25px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #789904; /* Black */
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
`;

interface ISubscribeFormContainer {
  from?: string;
}

export const SubscribeFormContainer = styled(
  motion.div
)<ISubscribeFormContainer>`
  .email {
    max-width: 420px;
    display: flex;
    padding-top: 0.5rem;
    margin: ${(p) => p.from && "0 auto"};
  }
  label {
    font-weight: 600;
    color: ${(p) => p.theme.colors.primary};
  }
  input[type="email"] {
    padding: 0.5rem;
    width: 100%;
    font-size: 1rem;
  }
  button {
    font-weight: 600;
    padding: 0.5rem 3rem;
    border: ${(p) => (p.from ? "2px solid white" : "none")};
    color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.1s ease-in all;
    background: ${(p) => p.theme.colors.primary};
    &:hover {
      background: ${(p) =>
        p.from ? p.theme.colors.secondary : p.theme.colors.secondary};
      color: white;
      border: ${(p) => (p.from ? "2px solid white" : "none")};
    }
  }
`;

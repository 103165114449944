import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import { FormContainer } from "./Forms.style";
import { FormFieldContainer } from "../FormField/FormField.style";
import LoaderComponent from "../Loader/LoaderComponent";
import { FaPaperPlane } from "react-icons/fa";
import { useToken } from "../../utils/customHooks";

interface IForms {
  className?: string;
  data?: any;
  campaignTitle: string;
  onSubmit?: (data: any) => void;
}
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
const SingleForm: FC<IForms> = (props: IForms) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { token } = useToken();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ mode: "all" });

  const getCurrentUrlWithoutProtocol = () => {
    var currentUrl = window.location.href;
    return currentUrl.replace(/^https?:\/\//i, "");
  };
  let currentUrl = getCurrentUrlWithoutProtocol();

  const onSubmit = (dataForm: any) => {
    setIsSubmitting(true);
    let commentContent: any = `Campaign Name: ${props.campaignTitle} \n`;
    dataForm.url = currentUrl;
    for (var key in dataForm) {
      if (dataForm.hasOwnProperty(key)) {
        commentContent += `${key} : ${dataForm[key]} \n`;
      }
    }
    let newData: any = {
      email: dataForm.email ? dataForm.email : "",
      firstname: dataForm.firstname ? dataForm.firstname : "",
      lastname: dataForm.lastname ? dataForm.lastname : "",
      memo: commentContent,
      phone: dataForm.phone ? dataForm.phone : "",
      website: "1",
      custbody_esc_campaign_category: -4,
      custbodyabco_campaign_name: props.campaignTitle,
      requestDemo: dataForm.requestDemo ? true : false,
      url: currentUrl,
    };

    if (token !== null) {
      if (token["utm_campaign"]) {
        newData["custbodyabco_campaign_name"] = token["utm_campaign"];
        newData["custbody_esc_campaign_category"] = -11;
      }
      if (token["gclid"]) {
        newData["custbody_esc_campaign_category"] = -15;
      }
    }
    fetch(
      // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3264&deploy=1&compid=6763524_SB2&h=7764186a3cda8ce0fca5&action=createOpportunity",
      "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&h=8daaa23e3101d43855eb&action=createOpportunity",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
        },
        body: JSON.stringify(newData),
      }
    )
      .then((res) => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "form_submission",
            form_name: "campaign-form",
          });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <FormContainer className={props.className}>
      <h2>Get in touch with our team today</h2>
      <p>Fill out the form below and we will contact you shortly</p>

      {!isSubmitting && !isSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.data.fieldList.map((field: any, index: any) => {
            if (field.fieldType === "select") {
              return (
                <FormFieldContainer
                  key={index}
                  className="form-field-container"
                >
                  <label htmlFor={field.fieldLabel}>
                    {field.fieldLabel}
                    {field.fieldRequired && "*"}
                  </label>
                  <div className="custom-select">
                    <select
                      id={field.fieldName}
                      placeholder={field.fieldPlaceholder}
                      {...register(field.fieldName, {
                        required: field.fieldRequired ? true : false,
                        min: field.fieldType === "number" ? 1000000 : 0,
                        pattern:
                          field.fieldType === "email"
                            ? {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              }
                            : undefined,
                      })}
                    >
                      <option value="">--Please Select--</option>
                      {field.optionList.map((option: any, key: number) => (
                        <option key={key} value={option.value}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                    <span className="custom-arrow"></span>
                  </div>
                  {errors[field.fieldName] && (
                    <span>{field.fieldLabel} is required</span>
                  )}
                </FormFieldContainer>
              );
            }
            return (
              <FormFieldContainer key={index} className="form-field-container">
                <label htmlFor={field.fieldLabel}>
                  {field.fieldLabel}
                  {field.fieldRequired && "*"}
                </label>
                <input
                  type={field.fieldType}
                  id={field.fieldName}
                  placeholder={field.fieldPlaceholder}
                  // value={formData[field.fieldName]}
                  {...register(field.fieldName, {
                    required: field.fieldRequired ? true : false,
                    minLength: field.fieldName === "phone" ? 7 : undefined,
                    pattern:
                      field.fieldName === "email"
                        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
                        : undefined,
                  })}
                />
                {errors[field.fieldName] && (
                  <span>
                    {field.fieldLabel} is required
                    {field.fieldName === "phone" &&
                      " and must have a minimum length of 7"}
                    {field.fieldName === "email" &&
                      " and must be a valid email address"}
                  </span>
                )}
              </FormFieldContainer>
            );
          })}

          <Button text="Submit" type="secondary" className="campaign-submit">
            <FaPaperPlane style={{ marginLeft: "1rem" }} />
          </Button>
        </form>
      ) : (
        <LoaderComponent />
      )}
      {isSubmitted && (
        <div>
          <div className="thank-you-step">
            <h1>Thank You</h1>
            <h2>We'll be in touch Shortly</h2>
          </div>
        </div>
      )}
    </FormContainer>
  );
};

export default SingleForm;

import React, { FC, useContext, useState } from "react";
import { catalogueFormVariants } from "../../Theme/animationVariants";
import {
  DownloadCatalogueFormContainer,
  FormFieldContainer,
} from "./Forms.style";
import { OutsideAlerter, Icons } from "../";
import { AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { StateContext } from "../../Context/ContextProvider";
import LoadingSpinner from "./LoadingSpinner";
import { IIcon } from "../../interfaces";
import { useToken } from "../../utils/customHooks";
interface IDownloadCatalogueForm {
  showCatalogue: boolean;
  data?: {
    image: any;
    file: any;
  };
}
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
/* eslint-disable no-useless-escape */

const DownloadCatalogueForm: FC<IDownloadCatalogueForm> = ({
  showCatalogue,
  data,
}) => {
  type formValues = {
    firstname: string;
    lastname: string;
    email: string;
    mobilephone: string;
    customerType: string;
    requestDemo: any;
  };
  const { token } = useToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonText, setButtonText] = useState("Download");
  const { catalogueData, setCatalogue, setCatalogueData } =
    useContext(StateContext);
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setPhoneNumber(data.mobilephone);
    catalogueData.fileName =
      catalogueData.fileName === undefined
        ? "download-catalogue"
        : catalogueData.fileName;
    catalogueData.url =
      catalogueData.url === undefined ? "test123" : window.location.href;
    let newData: any = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      memo: `File: ${catalogueData.file} \nFile Name: ${catalogueData.fileName} \nURL: ${catalogueData.url}`,
      phone: data.mobilephone,
      website: "1",
      custbody_esc_campaign_category: -4,
      customerType: data.customerType === "Private" ? 1 : 2,
      requestDemo: data.requestDemo ? true : false,
    };
    if (token !== null) {
      if (token["gclid"]) {
        newData["custbody_esc_campaign_category"] = -15;
      }
    }
    if (catalogueData.isManual) {
      window.open(catalogueData.file, "_blank");

      setCatalogueData({ file: "", isManual: false });
      setIsSubmitting(true);
      fetch(
        // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&h=feb67c19fa1747d29813&action=createOpportunity",
        "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&h=8daaa23e3101d43855eb&action=createOpportunity",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            "User-Agent":
              "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
          },
          body: JSON.stringify(newData),
        }
      ).then((res) => {
        setIsSubmitting(false);
        setCatalogue(false);
      });
      return;
    }
    const nameCleaned = catalogueData.file.replace(/\s/g, "-");
    window.open(nameCleaned, "_blank");
    setIsSubmitting(true);
    fetch(
      // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&h=feb67c19fa1747d29813&action=createOpportunity",
      "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&h=8daaa23e3101d43855eb&action=createOpportunity",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
        },
        body: JSON.stringify(newData),
      }
    ).then((res) => {
      setIsSubmitting(false);
      setTimeout(() => {
        setButtonText("Downloaded successfully!");
      }, 3000);
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_submission",
          form_name: "download-form",
        });
      }
      setCatalogue(false);
    });
    e.target.submit();
  };
  const onError = (errors: any, e: any) => console.log(errors, data, e);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formValues>({ mode: "all" });
  return (
    <AnimatePresence exitBeforeEnter>
      {showCatalogue && (
        <OutsideAlerter type="catalogue">
          <DownloadCatalogueFormContainer
            variants={catalogueFormVariants}
            animate="visible"
            initial="hidden"
            exit="hidden"
          >
            <div className="close-button">
              <Icons onClick={() => setCatalogue(false)} type={IIcon.close} />{" "}
            </div>
            {/* <h2>Get in touch with our team today</h2> */}
            <h2>Fill in the form below to download</h2>
            <form
              name="download-form"
              // method="post"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <FormFieldContainer>
                <label htmlFor="firstname">First Name*</label>
                <input
                  name="url"
                  value={catalogueData.file.replace(/\s/g, "-")}
                  readOnly
                  hidden
                />
                <input name="phone" value={phoneNumber} readOnly hidden />
                <input
                  id="firstname"
                  className={errors.firstname ? "error" : ""}
                  placeholder="John"
                  {...register("firstname", { required: true })}
                />
                {errors.firstname && errors.firstname.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}

                <label htmlFor="lastname">Last Name*</label>
                <input
                  id="lastname"
                  className={errors.lastname ? "error" : ""}
                  placeholder="Doe"
                  {...register("lastname", { required: true })}
                />
                {errors.lastname && errors.lastname.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="mobilephone">Mobile Number*</label>
                <input
                  className={errors.mobilephone ? "error" : ""}
                  type="number"
                  placeholder="0400 000 000"
                  id="phone"
                  {...register("mobilephone", { required: true })}
                />
                {errors.mobilephone &&
                errors.mobilephone.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="email">Email*</label>
                {/* eslint-disable-next-line  */}
                <input
                  id="email"
                  className={errors.email ? "error" : ""}
                  placeholder="example@address.com"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please Enter a valid email",
                    },
                  })}
                />
                <span>
                  {errors.email && errors.email.type === "required"
                    ? "This is required"
                    : errors.email &&
                      errors.email.type === "pattern" &&
                      "Please use a valid Email"}
                </span>
              </FormFieldContainer>
              <FormFieldContainer className="form-field-container">
                <label htmlFor={"customerType"}>Customer Type</label>
                <div className="custom-select">
                  <select
                    id="customerType"
                    placeholder="Customer Type"
                    {...register("customerType", {
                      required: true,
                    })}
                  >
                    <option value="">--Please Select--</option>
                    <option value={"Private"}>Private Customer</option>
                    <option value={"Business"}>Business Customer</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
                {errors.customerType && <span>Customer Type is required</span>}
              </FormFieldContainer>
              <FormFieldContainer className="form-field-container">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="requestDemo"
                    {...register("requestDemo")}
                  />
                  <label htmlFor="requestDemoCallback">
                    Request Demo or Call Back
                  </label>
                </div>
              </FormFieldContainer>

              {/* <input className="submitBtn" type="submit" value="Download" /> */}
              <button
                className="submitBtn"
                type="submit"
                disabled={isSubmitting}
                style={{ background: `${isSubmitting && "#244289"}` }}
              >
                {isSubmitting ? <LoadingSpinner /> : buttonText}
              </button>
            </form>
          </DownloadCatalogueFormContainer>
        </OutsideAlerter>
      )}
    </AnimatePresence>
  );
};

export default DownloadCatalogueForm;
